@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

:root {
    /* **************************** STATE => BASIC **************************** */
    --toc-dark-brown: #34180c;
    --toc-medium-brown: #4F352A;
    --toc-light-brown: #825745;
    --toc-brown: #35180C;
    --toc-white: #ffffff;
    --toc-black: #000;
    --toc-grey: #f2eeeb;
    --toc-light-grey: #d6cbc3;
    --toc-dark-grey: #707070;
    --toc-blue: #27ace3;
    --toc-dark-blue: #0079a4;
    --toc-orange: #ef7d00;
    --toc-dark-orange: #cf5f30;
    --toc-green: #2FB900;
    --toc-light-green: #ddf9e1;
    --toc-red: #EB1724;
    --toc-light-red: #ff2d3a;
    --toc-dark-red: #b7111c;
    --toc-danger: #fff1f2;
    --toc-payment: #003087;
    --toc-oney: #81BC00;
    --toc-dark-oney: #54565B;
    --toc-dark-click-and-collect : #bfb5b2;
    --toc-noug: linear-gradient(240deg, rgba(239, 125, 0, 1) 0%, rgba(207, 95, 48, 1) 100%);
    --toc-flash: linear-gradient(19deg, rgba(239, 125, 0, 1) 0%, rgba(255, 51, 51, 1) 100%);

    /* **************************** STATE => HOVER **************************** */
    --toc-noug-hover: linear-gradient(240deg, rgba(207, 95, 48, 1) 0%, rgba(207, 95, 48, 1) 100%);
    --toc-flash-hover: linear-gradient(19deg, rgba(255, 51, 51, 1) 0%, rgba(255, 51, 51, 1) 100%);
    --toc-orange-hover: #cf5f30;
}

html {
	font-size: 10px;
	scroll-behavior: smooth;
}

body {
	font-family: 'Montserrat', sans-serif !important;
	min-width: 320px;
	height: auto;
	line-height: 18px;
	font-size: 13px;
	color: #777;
}

body.content_only {
	background: none;
	min-width: 0px;
}

@media (max-width : 1200px) {
	body.overflow-hidding {
		overflow: hidden;
	}

	body .backdrop-menu.open {
		backdrop-filter: brightness(0.3);
		position: fixed;
		inset: 0;
		z-index: 100;
	}

	body .backdrop-menu.open .backdrop-menu-close {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 10rem;
		position: absolute;
		bottom: 0;
	}

	body .backdrop-menu.open .backdrop-menu-close::after {
		display: flex;
		align-items: center;
		font-family: "Line Awesome Free";
		font-weight: 900;
		content: '\f00d';
		font-size: 5rem;
		color: var(--toc-white);
	}
}

/*.header--scrolling + .main  {*/
/*	position: relative;*/
/*	top: 8rem;*/
/*}*/

textarea {
	resize: none;
}

ul {
	list-style: none;
	padding-inline-start: 0;
}

/* ************OVERRIDE BOOTSTRAP ************ */
@media (min-width : 1620px) {
	.col-xxl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-xxl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

/* //OVERRIDE BOOTSTRAP */
@media (min-width : 1200px) {
	.container {
		max-width: 1620px;
	}
}

/* ************OVERRIDE JQUERY BXSLIDER ************ */
.bx-wrapper {
	margin: auto !important;
}

.bx-controls-direction a {
	position: absolute !important;
	width: 5rem !important;
	height: 5rem !important;
	margin: 0 !important;
	top: 50% !important;
}

.bx-controls-direction a:before {
	color: var(--toc-orange) !important;
}

.bx-controls-direction a:hover:before {
	color: var(--toc-brown) !important;
}

.bx-controls-direction a.bx-prev {
	transform: translate(-100%, -50%) !important;
}

.bx-controls-direction a.bx-next {
	transform: translate(100%, -50%) !important;
}

.bx-controls-direction .bx-prev:before {
	content: '\f053' !important;
	font-family: 'Font Awesome 5 Free' !important;
}

.bx-controls-direction .bx-next:before {
	content: '\f054' !important;
	font-family: 'Font Awesome 5 Free' !important;
}

/* ************ Button GO TOP ************ */
.button-go-top {
	display: inline-block;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	width: 5rem;
	height: 5rem;
	text-align: center;
	border-radius: 50%;
	bottom: 30px;
	right: 30px;
	transition: background-color .3s, opacity .5s, visibility .5s;
	background-color: #f27b1f;
	z-index: 1000;
}

.button-go-top::after {
	content: "\f077";
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	inset: 0;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-size: 2rem;
	color: var(--toc-white);
}

.button-go-top:hover {
	cursor: pointer;
	background-color: var(--toc-dark-orange);
}

.button-go-top:active {
	background-color: var(--toc-dark-orange);
}

.button-go-top.show {
	opacity: 1;
	visibility: visible;
}

@media (max-width : 768px) {
	.button-go-top {
		width: 4rem;
		height: 4rem;
		bottom: 2rem;
		right: 2rem;
	}

	#page_product .button-go-top {
		bottom: 9rem;
	}

	#page_order .button-go-top {
		bottom: 5rem;
		box-shadow: hsl(0deg 0% 100%) 0 2px 0px 0px;
	}
}

/* ************ Spinner ************ */
.spinner-newsletter {
	margin: auto;
	width: fit-content;
	padding: 2rem 0;
}

.spinner-newsletter .spinner-border {
	width: 3rem;
	height: 3rem;
	color: var(--toc-orange);
}

.spinner-wishlist {
	width: 5rem;
	height: 5rem;
	display: grid;
	animation: spinner-for-wishlist 4s infinite;
}

.spinner-wishlist::before,
.spinner-wishlist::after {
	content: "";
	grid-area: 1/1;
	border: 9px solid;
	border-radius: 50%;
	border-color: var(--toc-orange) var(--toc-orange) #0000 #0000;
	mix-blend-mode: darken;
	animation: spinner-for-wishlist 1s infinite linear;
}

.spinner-wishlist::after {
	border-color: #0000 #0000 #dbdcef #dbdcef;
	animation-direction: reverse;
}

@keyframes spinner-for-wishlist {
	100% {
		transform: rotate(1turn);
	}
}


/* ************Générique Méthode ************ */
.width-100 {
	width: 100%;
}

.margin-elements {
	margin: 0 5rem !important;
}

.hidden {
	display: none;
}

.z-1 {
	z-index: 1;
}

.g-1 {
	gap: 1rem;
}

.g-2 {
	gap: 2rem;
}

.rg-1 {
	row-gap: 1rem;
}

.rg-2 {
	row-gap: 2rem;
}

.inset-0 {
	inset: 0 !important;
}

@media (max-width : 991px) {
	.hidden-lg {
		display: none !important;
	}
}

/* ************BREADCRUMB ************ */
#breadcrumb {
	margin-top: 2rem;
	text-transform: uppercase;
	color: var(--toc-brown);
}

#breadcrumb ol.breadcrumb__list {
	align-items: center;
	margin: 0;
	padding: 2rem 0 2rem;
	background: var(--toc-white);
}

#breadcrumb li {
	position: relative;
}

#breadcrumb li:not(:first-child)::before {
	display: inline-flex;
	padding: 0 1rem;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	content: '\f054';
	font-size: 1.4rem;
	color: var(--toc-orange);
	text-align: center;
}

#breadcrumb a {
	font-size: 1.4rem;
	color: var(--toc-brown);
}

#breadcrumb a:hover {
	color: var(--toc-orange);
}

#breadcrumb a span.back-home {
	color: var(--toc-orange);
}

#breadcrumb h5 {
	display: inline;
}

@media (min-width: 1200px) {
	#breadcrumb .container {
		max-width: 1620px;
	}
}

@media (max-width: 1200px) {
	#breadcrumb {
		margin-top: 0;
	}

	#breadcrumb li:not(:last-child, .breadcrumb__list__item-icon) {
		display: none;
	}
}

/* ************FORM VALIDATION ************ */
input.error, input.error-different {
	border: 1px solid var(--toc-red) !important;
	background: var(--toc-danger) !important;
}

input.success {
	border: 1px solid var(--toc-green) !important;
	background: var(--toc-light-green) !important;
}


/* ************Compare Product ************ */
.collapse-compare-product {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--toc-orange);
	z-index: 1001;
}

.collapse-compare-product-button {
	padding: 1rem;
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--toc-white);
	border: none;
	background: transparent;
}

.collapse-compare-product-text {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}
.collapse-compare-product-wrapper {
	position: relative;
	padding: 1rem;
	overflow: hidden;
	border-radius: 1rem;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.collapse-compare-product-wrapper-remove {
	position: absolute;
	top: 1rem;
	right: 1rem;
	padding: 0;
	border: none;
	background: none;
}

.collapse-compare-product-wrapper-remove:hover span i,
.collapse-compare-product-wrapper-remove:hover label i {
	color: var(--toc-red);
	cursor: pointer;
}

.collapse-compare-product-wrapper-item {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.collapse-compare-product-functionality {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	gap: 1rem;
}
.collapse-compare-product-functionality-button-cmp {
	display: inline-block;
	padding: 1rem 3rem;
	width: 100%;
	text-align: center;
	font-size: 1.7rem;
	text-transform: capitalize;
	font-weight: 500;
	cursor: pointer;
	color: var(--toc-white);
	background: var(--toc-blue);
	border-radius: 5rem;
	border: none;
	text-decoration: none;
}

.collapse-compare-product-functionality-button-cmp:hover {
	background: var(--toc-dark-blue);

}
.collapse-compare-product-functionality-button-removeAll {
	padding: 1rem 3rem;
	width: 100%;
	border-radius: 5rem;
	border: 1px solid var(--toc-brown);
	background: none;
}

@media (max-width: 768px) {
	#compareProducts {
		row-gap: 1rem;
	}

	.collapse-compare-product-functionality {
		button {
			width: 100%;
		}
	}
}

/* ************Générique Class' ************ */
.alert-toc {
	color: var(--toc-brown);
	background-color: var(--toc-light-grey);
}

.current-date-color {
	color: var(--toc-orange);
	font-weight: bold;
}



#index .tab-content {
	margin-top: 35px;
	margin-bottom: 34px;
}


header .banner {
	background: black;
	max-height: 100%;
}

header .banner .row {
	margin: 0px;
}

header .nav .row {
	margin: 0px;
}

header .nav nav {
	width: 100%;
}

header .row>.container {
	position: relative;
}

header .row #header_logo {
	padding-top: 15px;
}

@media (max-width: 992px) {
	header .row #header_logo {
		padding-top: 40px;
	}
}

@media (max-width: 767px) {
	header .row #header_logo {
		padding-top: 15px;
	}

	header .row #header_logo img {
		margin: 0 auto;
	}
}

@media (min-width: 767px) {

	header .col-sm-4+.col-sm-4+.col-sm-4,
	header .row #header_logo+.col-sm-4+.col-sm-4,
	header .row .col-sm-4+#header_logo+.col-sm-4,
	header .row #header_logo+#header_logo+.col-sm-4,
	header .row .col-sm-4+.col-sm-4+#header_logo,
	header .row #header_logo+.col-sm-4+#header_logo,
	header .row .col-sm-4+#header_logo+#header_logo,
	header .row #header_logo+#header_logo+#header_logo {
		float: right;
	}
}

.ie8 #header #header_logo {
	width: 350px;
}

#center_column .page_product_box h3 {
	color: #555454;
	text-transform: uppercase;
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 20px 17px;
	margin: 0 0 20px;
	position: relative;
	border: 1px solid #d6d4d4;
	border-bottom: none;
	background: #fbfbfb;
}

p.info-title {
	font-weight: bold;
	color: #333;
	margin-bottom: 25px;
}

p.info-account {
	margin: -4px 0 24px 0;
}

.dark {
	color: #333;
}

.main-page-indent {
	margin-bottom: 30px;
}

.alert {
	font-weight: bold;
}

.alert:before {
	cursor: pointer;
}

.alert ul,
.alert ol {
	padding-left: 15px;
	margin-left: 27px;
}

.alert ul li,
.alert ol li {
	list-style-type: decimal;
	font-weight: normal;
}

.alert.alert-success {
	text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.alert.alert-success:before {
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-size: 1.5rem;
	padding-right: 7px;
}

.alert.alert-danger {
	text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.alert.alert-danger:before {
	content: "\f00d";
	font-family: "Font Awesome 5 Free";
	font-size: 1.5rem;
	padding-right: 7px;
	float: left;
}

.label {
	white-space: normal;
	display: inline-block;
	padding: 6px 10px;
}

label {
	color: #333;
}

.unvisible {
	display: none;
}

.checkbox {
	line-height: 16px;
}

.checkbox label {
	color: #777777;
}

.close {
	opacity: 1;
}

.close:hover {
	opacity: 1;
}

input.button_mini,
input.button_small,
input.button,
input.button_large,
input.button_mini_disabled,
input.button_small_disabled,
input.button_disabled,
input.button_large_disabled,
input.exclusive_mini,
input.exclusive_small,
input.exclusive,
input.exclusive_large,
input.exclusive_mini_disabled,
input.exclusive_small_disabled,
input.exclusive_disabled,
input.exclusive_large_disabled,
a.button_mini,
a.button_small,
a.button,
a.button_large,
a.exclusive_mini,
a.exclusive_small,
a.exclusive,
a.exclusive_large,
span.button_mini,
span.button_small,
span.button,
span.button_large,
span.exclusive_mini,
span.exclusive_small,
span.exclusive,
span.exclusive_large,
span.exclusive_large_disabled {
	position: relative;
	display: inline-block;
	padding: 5px 7px;
	border: 1px solid #cc9900;
	font-weight: bold;
	color: black;
	background: url(../img/bg_bt.gif) repeat-x 0 0 #f4b61b;
	cursor: pointer;
	white-space: normal;
	text-align: left;
}

*:first-child+html input.button_mini,
*:first-child+html input.button_small,
*:first-child+html input.button,
*:first-child+html input.button_large,
*:first-child+html input.button_mini_disabled,
*:first-child+html input.button_small_disabled,
*:first-child+html input.button_disabled,
*:first-child+html input.button_large_disabled,
*:first-child+html input.exclusive_mini,
*:first-child+html input.exclusive_small,
*:first-child+html input.exclusive,
*:first-child+html input.exclusive_large,
*:first-child+html input.exclusive_mini_disabled,
*:first-child+html input.exclusive_small_disabled,
*:first-child+html input.exclusive_disabled,
*:first-child+html input.exclusive_large_disabled {
	border: none;
}

input.button_mini:hover,
input.button_small:hover,
input.button:hover,
input.button_large:hover,
input.exclusive_mini:hover,
input.exclusive_small:hover,
input.exclusive:hover,
input.exclusive_large:hover,
a.button_mini:hover,
a.button_small:hover,
a.button:hover,
a.button_large:hover,
a.exclusive_mini:hover,
a.exclusive_small:hover,
a.exclusive:hover,
a.exclusive_large:hover {
	text-decoration: none;
	background-position: left -50px;
}

input.button_mini:active,
input.button_small:active,
input.button:active,
input.button_large:active,
input.exclusive_mini:active,
input.exclusive_small:active,
input.exclusive:active,
input.exclusive_large:active,
a.button_mini:active,
a.button_small:active,
a.button:active,
a.button_large:active,
a.exclusive_mini:active,
a.exclusive_small:active,
a.exclusive:active,
a.exclusive_large:active {
	background-position: left -100px;
}

input.button_disabled,
input.exclusive_disabled,
span.exclusive {
	border: 1px solid #cccccc;
	color: #999999;
	background: url(../img/bg_bt_2.gif) repeat-x 0 0 #cccccc;
	cursor: default;
}

.btn.disabled,
.btn[disabled]:hover {
	opacity: 0.3;
}

.button.button-small {
	font: bold 13px/17px Arial, Helvetica, sans-serif;
	color: #fff;
	background: #6f6f6f;
	border: 1px solid;
	border-color: #666666 #5f5f5f #292929 #5f5f5f;
	padding: 0;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.24);
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.button.button-small span {
	display: block;
	padding: 3px 8px 3px 8px;
	border: 1px solid;
	border-color: #8b8a8a;
}

.button.button-small span i {
	vertical-align: 0px;
	margin-right: 5px;
}

.button.button-small span i.right {
	margin-right: 0;
	margin-left: 5px;
}

.button.button-small span:hover {
	background: #575757;
	border-color: #303030 #303030 #666666 #444444;
}

.button.button-medium {
	font-size: 17px;
	line-height: 21px;
	color: #fff;
	padding: 0;
	font-weight: bold;
	background: #43b754;
	background-image: -webkit-gradient(linear, left 0%, left 100%, from(#43b754), to(#42ac52));
	background-image: -webkit-linear-gradient(top, #43b754, 0%, #42ac52, 100%);
	background-image: -moz-linear-gradient(top, #43b754 0%, #42ac52 100%);
	background-image: linear-gradient(to bottom, #43b754 0%, #42ac52 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF43B754', endColorstr='#FF42AC52', GradientType=0);
	border: 1px solid;
	border-color: #399a49 #247f32 #1a6d27 #399a49;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.button.button-medium span {
	display: block;
	padding: 10px 10px 10px 14px;
	border: 1px solid;
	border-color: #74d578;
}

@media (max-width: 480px) {
	.button.button-medium span {
		font-size: 15px;
		padding-right: 7px;
		padding-left: 7px;
	}
}

.button.button-medium span i.left {
	font-size: 24px;
	vertical-align: -2px;
	margin: -4px 10px 0 0;
	display: inline-block;
}

@media (max-width: 480px) {
	.button.button-medium span i.left {
		margin-right: 5px;
	}
}

.button.button-medium span i.right {
	margin-right: 0;
	margin-left: 9px;
}

@media (max-width: 480px) {
	.button.button-medium span i.right {
		margin-left: 5px;
	}
}

.button.button-medium:hover {
	background: #3aa04c;
	background-image: -webkit-gradient(linear, left 0%, left 100%, from(#3aa04c), to(#3aa04a));
	background-image: -webkit-linear-gradient(top, #3aa04c, 0%, #3aa04a, 100%);
	background-image: -moz-linear-gradient(top, #3aa04c 0%, #3aa04a 100%);
	background-image: linear-gradient(to bottom, #3aa04c 0%, #3aa04a 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF3AA04C', endColorstr='#FF3AA04A', GradientType=0);
	border-color: #196f28 #399a49 #399a49 #258033;
}

.button.button-medium.exclusive {
	border-color: #db8600 #d98305 #c86d26 #d98305;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZkYWEwMiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZlOTcwMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
	background-size: 100%;
	background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fdaa02), color-stop(100%, #fe9702));
	background-image: -moz-linear-gradient(top, #fdaa02, #fe9702);
	background-image: -webkit-linear-gradient(top, #fdaa02, #fe9702);
	background-image: linear-gradient(to bottom, #fdaa02, #fe9702);
}

.button.button-medium.exclusive span {
	border-color: #fec133 #febc33 #feb233 #febc33;
}

.button.button-medium.exclusive:hover {
	background: #f89609;
	border-color: #a6550c #ba6708 #db8600 #ba6708;
}

.button.button-medium.exclusive:hover span {
	border-color: #fec133;
}

.link-button {
	font-weight: bold;
}

.link-button i {
	margin-right: 5px;
}

.link-button i.large {
	font-size: 26px;
	line-height: 26px;
	vertical-align: -3px;
	color: silver;
}

.button.exclusive-medium {
	font-size: 17px;
	padding: 0;
	line-height: 21px;
	color: #333;
	font-weight: bold;
	border: 1px solid;
	border-color: #cacaca #b7b7b7 #9a9a9a #b7b7b7;
	text-shadow: 1px 1px white;
}

.button.exclusive-medium span {
	border: 1px solid;
	border-color: #fff;
	display: block;
	padding: 9px 10px 11px 10px;
	background: #f7f7f7;
	background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f7f7f7), to(#ededed));
	background-image: -webkit-linear-gradient(top, #f7f7f7, 0%, #ededed, 100%);
	background-image: -moz-linear-gradient(top, #f7f7f7 0%, #ededed 100%);
	background-image: linear-gradient(to bottom, #f7f7f7 0%, #ededed 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF7F7F7', endColorstr='#FFEDEDED', GradientType=0);
}

@media (max-width: 480px) {
	.button.exclusive-medium span {
		font-size: 15px;
		padding-right: 7px;
		padding-left: 7px;
	}
}

.button.exclusive-medium span:hover {
	border-color: #9e9e9e #c2c2c2 #c8c8c8 #c2c2c2;
}

.button.ajax_add_to_cart_button {
	font: 700 17px/21px Arial, Helvetica, sans-serif;
	color: #fff;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
	padding: 0;
	border: 1px solid;
	border-color: #0079b6 #006fa8 #012740 #006fa8;
}

.button.ajax_add_to_cart_button span {
	border: 1px solid;
	border-color: #06b2e6;
	padding: 10px 14px;
	display: block;
	background: #009ad0;
	background-image: -webkit-gradient(linear, left 0%, left 100%, from(#009ad0), to(#007ab7));
	background-image: -webkit-linear-gradient(top, #009ad0, 0%, #007ab7, 100%);
	background-image: -moz-linear-gradient(top, #009ad0 0%, #007ab7 100%);
	background-image: linear-gradient(to bottom, #009ad0 0%, #007ab7 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF009AD0', endColorstr='#FF007AB7', GradientType=0);
}

.button.ajax_add_to_cart_button:hover {
	border-color: #01314e #004b74 #0079b6 #004b74;
}

.button.ajax_add_to_cart_button:hover span {
	filter: none;
	background: #0084bf;
}

.button.lnk_view {
	font: 700 17px/21px Arial, Helvetica, sans-serif;
	color: #333;
	text-shadow: 1px 1px white;
	padding: 0;
	border: 1px solid;
	border-color: #cacaca #b7b7b7 #9a9a9a #b7b7b7;
}

.button.lnk_view span {
	border: 1px solid;
	border-color: #fff;
	padding: 10px 14px;
	display: block;
	background: #f7f7f7;
	background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f7f7f7), to(#ededed));
	background-image: -webkit-linear-gradient(top, #f7f7f7, 0%, #ededed, 100%);
	background-image: -moz-linear-gradient(top, #f7f7f7 0%, #ededed 100%);
	background-image: linear-gradient(to bottom, #f7f7f7 0%, #ededed 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF7F7F7', endColorstr='#FFEDEDED', GradientType=0);
}

.button.lnk_view:hover {
	border-color: #9e9e9e #9e9e9e #c8c8c8 #9e9e9e;
}

.button.lnk_view:hover span {
	filter: none;
	background: #e7e7e7;
}

.button.lnk_view i.left {
	padding-right: 8px;
	color: #777777;
}

.form-control {
	padding: 3px 5px;
	height: 27px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control.grey {
	background: #fbfbfb;
}

table.std,
table.table_block {
	margin-bottom: 20px;
	width: 100%;
	border: 1px solid #999999;
	border-bottom: none;
	background: white;
	border-collapse: inherit;
}

table.std th,
table.table_block th {
	padding: 14px 12px;
	font-size: 12px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	text-shadow: 0 1px 0 black;
	background: #999999;
}

table.std tr.alternate_item,
table.table_block tr.alternate_item {
	background-color: #f3f3f3;
}

table.std td,
table.table_block td {
	padding: 12px;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	font-size: 12px;
	vertical-align: top;
}


.old-price {
	color: #6f6f6f;
	text-decoration: line-through;
}

.old-price.product-price {
	font-size: 1.2rem;
	margin-left: 1rem;
}

.special-price {
	color: #f13340;
}


.new-box,
.sale-box {
	position: absolute;
	top: -4px;
	overflow: hidden;
	height: 85px;
	width: 85px;
	text-align: center;
	z-index: 0;
}

.new-box {
	left: -4px;
}

.sale-box {
	right: -5px;
}

.new-label {
	font: 700 14px/12px Arial, Helvetica, sans-serif;
	color: #fff;
	background: #6ad4ff;
	text-transform: uppercase;
	padding: 9px 0 7px;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.24);
	width: 130px;
	text-align: center;
	display: block;
	position: absolute;
	left: -33px;
	top: 16px;
	z-index: 1;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.new-label:before {
	position: absolute;
	bottom: -3px;
	right: 5px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 4px 4px 0px 4px;
	border-color: #21a3d8 transparent transparent transparent;
	content: ".";
	text-indent: -5000px;
	-webkit-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
}

.new-label:after {
	position: absolute;
	bottom: -3px;
	left: 3px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 4px 4px 0px 4px;
	border-color: #21a3d8 transparent transparent transparent;
	content: ".";
	text-indent: -5000px;
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}

.sale-label {
	font: 700 14px/12px Arial, Helvetica, sans-serif;
	color: #fff;
	background: var(--toc-red);
	text-transform: uppercase;
	padding: 9px 0 7px;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.24);
	width: 130px;
	text-align: center;
	display: block;
	position: absolute;
	right: -33px;
	top: 16px;
	z-index: 1;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.sale-label:before {
	position: absolute;
	bottom: -3px;
	right: 4px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 4px 4px 0px 4px;
	border-color: #ad2b34 transparent transparent transparent;
	content: ".";
	text-indent: -5000px;
	-webkit-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
}

.sale-label:after {
	position: absolute;
	bottom: -3px;
	left: 5px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 4px 4px 0px 4px;
	border-color: #ad2b34 transparent transparent transparent;
	content: ".";
	text-indent: -5000px;
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}

.ie8 .new-label {
	left: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}

.ie8 .new-label:after {
	display: none;
}

.ie8 .new-label:before {
	display: none;
}

.ie8 .sale-label {
	right: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}

.ie8 .sale-label:after {
	display: none;
}

.ie8 .sale-label:before {
	display: none;
}

.ie8 .new-box {
	top: -1px;
	left: -1px;
}

.ie8 .sale-box {
	right: -1px;
	top: -1px;
}

.box {
	background: #fbfbfb;
	border: 1px solid #d6d4d4;
	padding: 14px 18px 13px;
	margin: 0 0 30px 0;
	line-height: 23px;
}

.box p {
	margin-bottom: 0;
}

.box.box-small {
	padding: 9px 10px 9px 20px;
}

.box.box-small .dark {
	padding-top: 10px;
	padding-right: 20px;
	margin-bottom: 0;
	width: auto;
}

.page-product-box {
	padding-bottom: 10px;
}

.product-box {
	width: 178px;
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.product-box .product-image {
	border: 1px solid #d6d4d4;
	background: white;
	padding: 5px;
	display: block;
	margin-bottom: 11px;
}

.product-box .product-image img {
	max-width: 100%;
	width: 100%;
	height: auto;
}

#home-page-tabs {
	border: none;
	background: #fff;
}

@media (min-width: 768px) {
	#home-page-tabs {
		padding: 10px 0;
		margin: 0 0 0 -18px;
	}
}

#home-page-tabs>li {
	margin-bottom: 0;
}

@media (min-width: 991px) {
	#home-page-tabs>li {
		border-left: 1px solid #d6d4d4;
		margin: 0 0px 0 9px;
		padding: 0 0px 0 9px;
	}
}

@media (max-width: 479px) {
	#home-page-tabs>li {
		width: 100%;
	}
}

#home-page-tabs>li a {
	font: 600 21px/24px "Open Sans", sans-serif;
	color: #555454;
	text-transform: uppercase;
	border: none;
	outline: none;
	margin: 0;
	padding: 10px;
}

@media (min-width: 768px) {
	#home-page-tabs>li a {
		padding: 1px 10px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#home-page-tabs>li a {
		padding: 10px 10px;
	}
}

#home-page-tabs>li:first-child {
	border: none;
}

#home-page-tabs>li.active a,
#home-page-tabs>li a:hover {
	background: #333;
	color: #fff;
	padding: 10px;
}

@media (min-width: 768px) {

	#home-page-tabs>li.active a,
	#home-page-tabs>li a:hover {
		margin: -9px 0;
	}
}

@media (min-width: 768px) and (max-width: 991px) {

	#home-page-tabs>li.active a,
	#home-page-tabs>li a:hover {
		margin: 0px 0;
	}
}

.block {
	margin-bottom: 30px;
}

@media (max-width: 767px) {
	.block {
		margin-bottom: 0px;
	}
}

@media (max-width: 767px) {
	.block .block_content {
		margin-bottom: 20px;
	}
}

.block .title_block,
.block h4 {
	font: 600 18px/22px "Open Sans", sans-serif;
	color: #555454;
	background: #f6f6f6;
	border-top: 5px solid #333;
	text-transform: uppercase;
	padding: 14px 5px 17px 20px;
	margin-bottom: 20px;
}

@media (min-width: 768px) and (max-width: 991px) {

	.block .title_block,
	.block h4 {
		font-size: 14px;
	}
}

@media (max-width: 767px) {

	.block .title_block,
	.block h4 {
		position: relative;
	}

	.block .title_block:after,
	.block h4:after {
		display: block;
		font-family: "FontAwesome";
		content: "";
		position: absolute;
		right: 0;
		top: 15px;
		height: 36px;
		width: 36px;
		font-size: 26px;
		font-weight: normal;
	}

	.block .title_block.active:after,
	.block h4.active:after {
		content: "";
	}
}

.block .title_block a,
.block h4 a {
	color: #555454;
}

.block .title_block a:hover,
.block h4 a:hover {
	color: #333;
}

.block .list-block {
	margin-top: -8px;
}

@media (max-width: 767px) {
	.block .list-block {
		margin-top: 0;
	}
}

.block .list-block li {
	padding: 5px 0 6px 20px;
	border-top: 1px solid #d6d4d4;
}

.block .list-block li a i {
	display: none;
}

.block .list-block li a:before {
	content: "\f105";
	display: inline;
	font-family: "FontAwesome";
	color: #333;
	padding-right: 8px;
}

.block .list-block li a:hover {
	color: #333;
	font-weight: bold;
}

.block .list-block li:first-child {
	border-top: none;
}

.block .list-block .form-group {
	padding-top: 20px;
	border-top: 1px solid #d6d4d4;
	margin-bottom: 0;
}

.block .list-block .form-group select {
	max-width: 270px;
}

@media (max-width: 767px) {
	.block .list-block .form-group select {
		width: 270px;
	}
}

.block .list-block .btn {
	margin-top: 12px;
}

.block .products-block li {
	padding: 0 0 20px 0;
	margin-bottom: 20px;
	border-bottom: 1px solid #d6d4d4;
}

.block .products-block li .products-block-image {
	float: left;
	border: 1px solid #d6d4d4;
	margin-right: 19px;
}

@media (min-width: 768px) and (max-width: 991px) {
	.block .products-block li .products-block-image {
		float: none;
		display: inline-block;
		margin: 0 auto 10px;
		text-align: center;
	}
}

.block .products-block li .product-content {
	overflow: hidden;
}

.block .products-block li .product-content h5 {
	position: relative;
	top: -11px;
}

.block .products-block .product-name {
	font-size: 15px;
	line-height: 18px;
}

.block .products-block .product-description {
	margin-bottom: 14px;
}

.block .products-block .price-percent-reduction {
	font: 700 14px/17px Arial, Helvetica, sans-serif;
	padding: 1px 6px;
}

.page-heading span.heading-counter {
	font: bold 13px/22px Arial, Helvetica, sans-serif;
	float: right;
	color: #333;
	text-transform: none;
	margin-bottom: 10px;
}

@media (max-width: 480px) {
	.page-heading span.heading-counter {
		float: none;
		display: block;
		padding-top: 5px;
	}
}

.page-heading span.lighter {
	color: #9c9c9c;
}

.page-heading.bottom-indent {
	margin-bottom: 16px;
}

.page-heading.product-listing {
	border-bottom: none;
	margin-bottom: 0;
}

.page-subheading {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	color: #555454;
	font-size: 18px;
	padding: 0 0 15px;
	line-height: normal;
	margin-bottom: 12px;
	border-bottom: 1px solid #d6d4d4;
}

h3.page-product-heading {
	color: #555454;
	text-transform: uppercase;
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 20px 17px;
	margin: 0 0 20px;
	position: relative;
	border: 1px solid #d6d4d4;
	border-bottom: none;
	background: #fbfbfb;
}


.content_sortPagiBar .sortPagiBar {
	border-bottom: 1px solid #d6d4d4;
	clear: both;
}

.content_sortPagiBar .sortPagiBar #productsSortForm {
	float: left;
	margin-right: 20px;
	margin-bottom: 10px;
}

.content_sortPagiBar .sortPagiBar #productsSortForm select {
	max-width: 192px;
	float: left;
}

@media (max-width: 991px) {
	.content_sortPagiBar .sortPagiBar #productsSortForm select {
		max-width: 160px;
	}
}

.content_sortPagiBar .sortPagiBar #productsSortForm .selector {
	float: left;
}

.content_sortPagiBar .sortPagiBar .nbrItemPage {
	float: left;
}

.content_sortPagiBar .sortPagiBar .nbrItemPage select {
	max-width: 59px;
	float: left;
}

.content_sortPagiBar .sortPagiBar .nbrItemPage .clearfix>span {
	padding: 3px 0 0 12px;
	display: inline-block;
	float: left;
}

.content_sortPagiBar .sortPagiBar label,
.content_sortPagiBar .sortPagiBar select {
	float: left;
}

.content_sortPagiBar .sortPagiBar label {
	padding: 3px 6px 0 0;
}

.content_sortPagiBar .sortPagiBar.instant_search #productsSortForm {
	display: none;
}

.content_sortPagiBar .display,
.content_sortPagiBar .display_m {
	float: right;
	margin-top: -4px;
}

.content_sortPagiBar .display li,
.content_sortPagiBar .display_m li {
	float: left;
	padding-left: 12px;
	text-align: center;
}

.content_sortPagiBar .display li a,
.content_sortPagiBar .display_m li a {
	color: #808080;
	font-size: 11px;
	line-height: 14px;
	cursor: pointer;
}

.content_sortPagiBar .display li a i,
.content_sortPagiBar .display_m li a i {
	display: block;
	font-size: 24px;
	height: 24px;
	line-height: 24px;
	margin-bottom: -3px;
	color: #e1e0e0;
}

.content_sortPagiBar .display li a:hover i,
.content_sortPagiBar .display_m li a:hover i {
	color: gray;
}

.content_sortPagiBar .display li.selected a,
.content_sortPagiBar .display_m li.selected a {
	cursor: default;
}

.content_sortPagiBar .display li.selected i,
.content_sortPagiBar .display_m li.selected i {
	color: #333;
}

.content_sortPagiBar .display li.display-title,
.content_sortPagiBar .display_m li.display-title {
	font-weight: bold;
	color: #333;
	padding: 7px 6px 0 0;
}

.top-pagination-content,
.bottom-pagination-content {
	text-align: center;
	padding: 12px 0 12px 0;
	position: relative;
}

.top-pagination-content div.pagination,
.bottom-pagination-content div.pagination {
	margin: 0;
	float: right;
	width: 530px;
	text-align: center;
}

@media (min-width: 992px) and (max-width: 1199px) {

	.top-pagination-content div.pagination,
	.bottom-pagination-content div.pagination {
		width: 380px;
	}
}

@media (max-width: 991px) {

	.top-pagination-content div.pagination,
	.bottom-pagination-content div.pagination {
		float: left;
		width: auto;
	}
}

.top-pagination-content div.pagination .showall,
.bottom-pagination-content div.pagination .showall {
	float: right;
	margin: 8px 53px 8px 14px;
}

@media (min-width: 992px) and (max-width: 1199px) {

	.top-pagination-content div.pagination .showall,
	.bottom-pagination-content div.pagination .showall {
		margin-right: 11px;
	}
}

@media (max-width: 991px) {

	.top-pagination-content div.pagination .showall,
	.bottom-pagination-content div.pagination .showall {
		margin-right: 0;
	}
}

.top-pagination-content div.pagination .showall .btn span,
.bottom-pagination-content div.pagination .showall .btn span {
	font-size: 13px;
	padding: 3px 5px 4px 5px;
	line-height: normal;
}

.top-pagination-content ul.pagination,
.bottom-pagination-content ul.pagination {
	margin: 8px 0px 8px 0;
}

@media (max-width: 991px) {

	.top-pagination-content ul.pagination,
	.bottom-pagination-content ul.pagination {
		float: left;
	}
}

.top-pagination-content ul.pagination li,
.bottom-pagination-content ul.pagination li {
	display: inline-block;
	float: left;
}

.top-pagination-content ul.pagination li>a,
.top-pagination-content ul.pagination li>span,
.bottom-pagination-content ul.pagination li>a,
.bottom-pagination-content ul.pagination li>span {
	margin: 0 1px 0 0px;
	padding: 0;
	font-weight: bold;
	border: 1px solid;
	border-color: #dfdede #d2d0d0 #b0afaf #d2d0d0;
	display: block;
}

.top-pagination-content ul.pagination li>a span,
.top-pagination-content ul.pagination li>span span,
.bottom-pagination-content ul.pagination li>a span,
.bottom-pagination-content ul.pagination li>span span {
	border: 1px solid #fff;
	padding: 2px 8px;
	display: block;
	background: url(../img/pagination-li.gif) 0 0 repeat-x #fbfbfb;
}

.top-pagination-content ul.pagination li>a:hover span,
.bottom-pagination-content ul.pagination li>a:hover span {
	background: #f6f6f6;
}

.top-pagination-content ul.pagination li.pagination_previous,
.top-pagination-content ul.pagination li.pagination_next,
.bottom-pagination-content ul.pagination li.pagination_previous,
.bottom-pagination-content ul.pagination li.pagination_next {
	color: #777676;
	font-weight: bold;
}

.top-pagination-content ul.pagination li.pagination_previous>a,
.top-pagination-content ul.pagination li.pagination_previous>span,
.top-pagination-content ul.pagination li.pagination_next>a,
.top-pagination-content ul.pagination li.pagination_next>span,
.bottom-pagination-content ul.pagination li.pagination_previous>a,
.bottom-pagination-content ul.pagination li.pagination_previous>span,
.bottom-pagination-content ul.pagination li.pagination_next>a,
.bottom-pagination-content ul.pagination li.pagination_next>span {
	border: none;
	background: none;
	display: block;
	padding: 4px 0;
}

@media (max-width: 767px) {

	.top-pagination-content ul.pagination li.pagination_previous>a b,
	.top-pagination-content ul.pagination li.pagination_previous>span b,
	.top-pagination-content ul.pagination li.pagination_next>a b,
	.top-pagination-content ul.pagination li.pagination_next>span b,
	.bottom-pagination-content ul.pagination li.pagination_previous>a b,
	.bottom-pagination-content ul.pagination li.pagination_previous>span b,
	.bottom-pagination-content ul.pagination li.pagination_next>a b,
	.bottom-pagination-content ul.pagination li.pagination_next>span b {
		display: none;
	}
}

.top-pagination-content ul.pagination li.pagination_previous>a span,
.top-pagination-content ul.pagination li.pagination_previous>span span,
.top-pagination-content ul.pagination li.pagination_next>a span,
.top-pagination-content ul.pagination li.pagination_next>span span,
.bottom-pagination-content ul.pagination li.pagination_previous>a span,
.bottom-pagination-content ul.pagination li.pagination_previous>span span,
.bottom-pagination-content ul.pagination li.pagination_next>a span,
.bottom-pagination-content ul.pagination li.pagination_next>span span {
	border: none;
	padding: 0;
	background: none;
}

.top-pagination-content ul.pagination li.pagination_previous>a span b,
.top-pagination-content ul.pagination li.pagination_previous>span span b,
.top-pagination-content ul.pagination li.pagination_next>a span b,
.top-pagination-content ul.pagination li.pagination_next>span span b,
.bottom-pagination-content ul.pagination li.pagination_previous>a span b,
.bottom-pagination-content ul.pagination li.pagination_previous>span span b,
.bottom-pagination-content ul.pagination li.pagination_next>a span b,
.bottom-pagination-content ul.pagination li.pagination_next>span span b {
	font-weight: bold;
}

.top-pagination-content ul.pagination li.pagination_previous,
.bottom-pagination-content ul.pagination li.pagination_previous {
	margin-right: 10px;
}

.top-pagination-content ul.pagination li.pagination_next,
.bottom-pagination-content ul.pagination li.pagination_next {
	margin-left: 10px;
}

.top-pagination-content ul.pagination li.active>span,
.bottom-pagination-content ul.pagination li.active>span {
	color: #333;
	border-color: #dfdede #d2d0d0 #b0afaf #d2d0d0;
}

.top-pagination-content ul.pagination li.active>span span,
.bottom-pagination-content ul.pagination li.active>span span {
	background: #f6f6f6;
}

.top-pagination-content .compare-form,
.bottom-pagination-content .compare-form {
	float: right;
}

@media (max-width: 479px) {

	.top-pagination-content .compare-form,
	.bottom-pagination-content .compare-form {
		float: left;
		width: 100%;
		text-align: left;
		padding-bottom: 10px;
		clear: both;
	}
}

.top-pagination-content .product-count,
.bottom-pagination-content .product-count {
	padding: 11px 0 0 0;
	float: left;
}

@media (max-width: 991px) {

	.top-pagination-content .product-count,
	.bottom-pagination-content .product-count {
		clear: left;
	}
}

.bottom-pagination-content {
	border-top: 1px solid #d6d4d4;
}

.rating {
	clear: both;
	display: block;
	margin: 2em;
	cursor: pointer;
}

.rating:after {
	content: ".";
	display: block;
	height: 0;
	width: 0;
	clear: both;
	visibility: hidden;
}

.cancel,
.star {
	overflow: hidden;
	float: left;
	margin: 0 1px 0 0;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.cancel,
.cancel a {
	background: url(../../../modules/productcomments/img/delete.gif) no-repeat 0 -16px !important;
}

.cancel a,
.star a {
	display: block;
	width: 100%;
	height: 100%;
	background-position: 0 0;
}

div.star_on a {
	background-position: 0 -16px;
}

div.star_hover a,
div.star a:hover {
	background-position: 0 -32px;
}

.pack_content {
	margin: 10px 0 10px 0;
}

.confirmation {
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid #e6db55;
	font-size: 13px;
	background: none repeat scroll 0 0 lightyellow;
}

#page .rte {
	background: transparent none repeat scroll 0 0;
}

#page .rte em {
	font-style: italic !important;
}

.listcomment {
	list-style-type: none;
	margin: 0 0 20px 0 !important;
}

.listcomment li {
	padding: 10px 0;
	border-bottom: 1px dotted #d6d4d4;
	color: #666666;
}

.listcomment .titlecomment {
	display: block;
	font-weight: bold;
	font-size: 12px;
	color: #333;
}

.listcomment .txtcomment {
	display: block;
	padding: 5px 0;
	color: #333;
}

.header-container {
	background: #fff;
}

.footer-container {
	background-color: #333;
}

@media (min-width: 768px) {
	.footer-container {
		background: url(../img/footer-bg.png) repeat-x #333;
	}
}

.footer-container .container {
	padding-bottom: 100px;
}

.footer-container #footer {
	color: #777777;
}

.footer-container #footer .row {
	position: relative;
}

.footer-container #footer ul.row {
	position: static;
}

.footer-container #footer .footer-block {
	margin-top: 45px;
}

@media (max-width: 767px) {
	.footer-container #footer .footer-block {
		margin-top: 20px;
	}
}

.footer-container #footer a {
	color: #777777;
}

.footer-container #footer a:hover {
	color: #fff;
}

.footer-container #footer h4 {
	font: 600 18px/22px "Open Sans", sans-serif;
	color: #fff;
	margin: 0 0 13px 0;
	cursor: pointer;
}

@media (max-width: 767px) {
	.footer-container #footer h4 {
		position: relative;
		margin-bottom: 0;
		padding-bottom: 13px;
	}

	.footer-container #footer h4:after {
		display: block;
		content: "\f055";
		font-family: "FontAwesome";
		position: absolute;
		right: 0;
		top: 1px;
	}

	.footer-container #footer h4.active:after {
		content: "\f056";
	}
}

.footer-container #footer h4 a {
	color: #fff;
}

.footer-container #footer ul li {
	padding-bottom: 8px;
}

.footer-container #footer ul li a {
	font-weight: bold;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
}

.footer-container #footer #block_contact_infos {
	border-left: 1px solid #515151;
}

@media (max-width: 767px) {
	.footer-container #footer #block_contact_infos {
		border: none;
	}
}

.footer-container #footer #block_contact_infos>div {
	padding: 0 0 0 5px;
}

@media (max-width: 767px) {
	.footer-container #footer #block_contact_infos>div {
		padding-left: 0;
	}
}

.footer-container #footer #block_contact_infos>div ul li {
	padding: 0 0 7px 4px;
	overflow: hidden;
	line-height: 30px;
}

.footer-container #footer #block_contact_infos>div ul li>span,
.footer-container #footer #block_contact_infos>div ul li>span a {
	color: #fff;
	font-weight: normal;
}

.footer-container #footer #block_contact_infos>div ul li i {
	font-size: 25px;
	width: 32px;
	text-align: center;
	padding-right: 12px;
	float: left;
	color: #908f8f;
}

.footer-container #footer .blockcategories_footer {
	clear: left;
}

.footer-container #footer #social_block {
	float: left;
	width: 50%;
	padding: 22px 15px 0 15px;
}

@media (max-width: 767px) {
	.footer-container #footer #social_block {
		width: 100%;
		float: left;
		padding-top: 5px;
	}
}

.footer-container #footer #social_block ul {
	float: right;
}

@media (max-width: 767px) {
	.footer-container #footer #social_block ul {
		float: none;
	}
}

.footer-container #footer #social_block ul li {
	float: left;
	width: 40px;
	text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
	.footer-container #footer #social_block ul li {
		width: 30px;
	}
}

.footer-container #footer #social_block ul li a {
	display: inline-block;
	color: #908f8f;
	font-size: 28px;
}

@media (min-width: 768px) and (max-width: 991px) {
	.footer-container #footer #social_block ul li a {
		font-size: 20px;
	}
}

.footer-container #footer #social_block ul li a span {
	display: none;
}

.footer-container #footer #social_block ul li a:before {
	display: inline-block;
	font-family: "FontAwesome";
	vertical-align: -5px;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.footer-container #footer #social_block ul li a:hover {
	color: #fff;
}

.footer-container #footer #social_block ul li.facebook a:before {
	content: "\f09a";
}

.footer-container #footer #social_block ul li.twitter a:before {
	content: "\f099";
}

.footer-container #footer #social_block ul li.rss a:before {
	content: "\f09e";
}

.footer-container #footer #social_block ul li.youtube a:before {
	content: "\f167";
}

.footer-container #footer #social_block ul li.google-plus a:before {
	content: "\f0d5";
}

.footer-container #footer #social_block ul li.pinterest a:before {
	content: "\f0d2";
}

.footer-container #footer #social_block ul li.vimeo a:before {
	content: "\f194";
}

.footer-container #footer #social_block ul li.instagram a:before {
	content: "\f16d";
}

.footer-container #footer #social_block h4 {
	float: right;
	margin-bottom: 0;
	font-size: 21px;
	line-height: 25px;
	text-transform: none;
	padding: 0 10px 0 0;
}

@media (max-width: 767px) {
	.footer-container #footer #social_block h4 {
		display: none;
		text-align: center;
	}
}

.footer-container #footer .bottom-footer {
	position: absolute;
	bottom: -55px;
	left: 0;
}

.footer-container #footer .bottom-footer div {
	padding: 15px 0 0 0;
	border-top: 1px solid #515151;
	width: 100%;
}

#reinsurance_block {
	clear: both !important;
}

#short_description_content ul,
#short_description_content ol,
#short_description_content dl,
#tinymce ul,
#tinymce ol,
#tinymce dl {
	margin-left: 20px;
}

.rte ul {
	list-style-type: disc;
	padding-left: 15px;
}

.rte ol {
	list-style-type: decimal;
	padding-left: 15px;
}

.block_hidden_only_for_screen {
	display: none;
}

.cart_navigation {
	margin: 0 0 20px;
}

.cart_navigation .button-medium {
	float: right;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	font-size: 20px;
	line-height: 24px;
}

.cart_navigation .button-medium span {
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 11px 15px 10px 15px;
}

@media (max-width: 992px) {
	.cart_navigation .button-medium span {
		font-size: 16px;
	}
}

.cart_navigation .button-medium i.right {
	font-size: 25px;
	line-height: 25px;
	vertical-align: -4px;
	margin-left: 6px;
}

.cart_navigation .button-exclusive {
	border: none;
	background: none;
	padding: 0;
	font-size: 17px;
	font-weight: bold;
	color: #333;
	margin: 9px 0 0 0;
}

.cart_navigation .button-exclusive i {
	color: #777777;
	margin-right: 8px;
}

.cart_navigation .button-exclusive:hover,
.cart_navigation .button-exclusive:focus,
.cart_navigation .button-exclusive:active {
	color: #515151;
	-webkit-box-shadow: none;
	box-shadow: none;
}

@media (max-width: 480px) {
	.cart_navigation>span {
		display: block;
		width: 100%;
		padding-bottom: 15px;
	}
}

.cart_last_product {
	display: none;
}

.table tbody>tr>td {
	vertical-align: middle;
}

.table tbody>tr>td.cart_quantity {
	padding: 41px 14px 25px;
	width: 70px;
}

.table tbody>tr>td.cart_quantity .cart_quantity_button {
	margin-top: 3px;
}

.table tbody>tr>td.cart_quantity .cart_quantity_button a {
	float: left;
	margin-right: 3px;
}

.table tbody>tr>td.cart_quantity .cart_quantity_button a+a {
	margin-right: 0;
}

.table tbody>tr>td.cart_delete,
.table tbody>tr>td.price_discount_del {
	padding: 5px;
}

.table tfoot>tr>td {
	vertical-align: middle;
}

.cart_delete a.cart_quantity_delete,
a.price_discount_delete {
	font-size: 23px;
	color: #333;
}

.cart_delete a.cart_quantity_delete:hover,
a.price_discount_delete:hover {
	color: silver;
}



.enable-multishipping {
	margin: -13px 0 17px;
}

.enable-multishipping label {
	font-weight: normal;
}

#HOOK_SHOPPING_CART #loyalty i {
	font-size: 26px;
	line-height: 26px;
	color: #cfcccc;
}

#order_carrier {
	clear: both;
	margin-top: 20px;
	border: 1px solid #999999;
	background: #fff;
}

.order_carrier_logo {
	max-width: 65px;
	max-height: 65px;
}

#order_carrier .title_block {
	padding: 0 15px;
	height: 29px;
	font-weight: bold;
	line-height: 29px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	background: url(../img/bg_table_th.png) repeat-x 0 -10px #999999;
}

#order_carrier span {
	display: block;
	padding: 15px;
	font-weight: bold;
}

.multishipping_close_container {
	text-align: center;
}

table#cart_summary .gift-icon {
	color: white;
	background: #0088cc;
	display: inline;
	line-height: 20px;
	padding: 2px 5px;
	border-radius: 5px;
}

#multishipping_mode_box {
	padding-top: 12px;
	padding-bottom: 19px;
}

#multishipping_mode_box .title {
	font-weight: bold;
	color: #333;
	margin-bottom: 15px;
}

#multishipping_mode_box .description_off {
	display: none;
}

#multishipping_mode_box .description_off div {
	margin-bottom: 10px;
}

#multishipping_mode_box .description_off a:hover,
#multishipping_mode_box .description a:hover {
	background: #f3f3f3;
	border: 1px solid #cccccc;
}

#multishipping_mode_box.on .description_off {
	display: block;
}

.multishipping-cart .cart_address_delivery.form-control {
	width: 198px;
}

#order .address_add.submit {
	margin-bottom: 20px;
}

#ordermsg {
	margin-bottom: 30px;
}

#id_address_invoice.form-control,
#id_address_delivery.form-control {
	width: 269px;
}

.addresses .select label,
.addresses .selector,
.addresses .addresses .address_select {
	clear: both;
	float: left;
}

.addresses .waitimage {
	display: none;
	float: left;
	width: 24px;
	height: 24px;
	background-image: url("../../../img/loader.gif");
	background-repeat: no-repeat;
	position: relative;
	bottom: -2px;
	left: 10px;
}

.addresses .checkbox.addressesAreEquals {
	clear: both;
	float: left;
	margin: 15px 0 25px 0;
}

#order .delivery_option>div,
#order-opc .delivery_option>div {
	display: block;
	margin-bottom: 20px;
	font-weight: normal;
}

#order .delivery_option>div>table,
#order-opc .delivery_option>div>table {
	background: #fff;
	margin-bottom: 0;
}

#order .delivery_option>div>table.resume,
#order-opc .delivery_option>div>table.resume {
	height: 53px;
}

#order .delivery_option>div>table.resume.delivery_option_carrier,
#order-opc .delivery_option>div>table.resume.delivery_option_carrier {
	margin-top: 10px;
	width: 100%;
	border: 1px solid #d6d4d4;
	font-weight: bold;
}

#order .delivery_option>div>table.resume.delivery_option_carrier td,
#order-opc .delivery_option>div>table.resume.delivery_option_carrier td {
	padding: 8px 11px 7px 11px;
}

#order .delivery_option>div>table.resume.delivery_option_carrier td i,
#order-opc .delivery_option>div>table.resume.delivery_option_carrier td i {
	font-size: 20px;
	margin-right: 7px;
	vertical-align: -2px;
}

#order .delivery_option>div>table.resume td.delivery_option_radio,
#order-opc .delivery_option>div>table.resume td.delivery_option_radio {
	width: 54px;
	padding-left: 0;
	padding-right: 0;
	text-align: center;
}

#order .delivery_option>div>table.resume td.delivery_option_logo,
#order-opc .delivery_option>div>table.resume td.delivery_option_logo {
	width: 97px;
	padding-left: 21px;
}

#order .delivery_option>div>table.resume td.delivery_option_price,
#order-opc .delivery_option>div>table.resume td.delivery_option_price {
	width: 162px;
}

.order_carrier_content {
	line-height: normal;
	padding-bottom: 16px;
}

.order_carrier_content .carrier_title {
	font-weight: bold;
	color: #333;
}

.order_carrier_content .checkbox input {
	margin-top: 2px;
}

.order_carrier_content .delivery_options_address .carrier_title {
	margin-bottom: 17px;
}

.order_carrier_content+div {
	padding-bottom: 15px;
}

#carrierTable {
	border: 1px solid #999999;
	border-bottom: none;
	background: #fff;
}

#carrierTable tbody {
	border-bottom: 1px solid #999999;
}

#carrierTable th {
	padding: 0 15px;
	height: 29px;
	font-weight: bold;
	line-height: 29px;
	color: #fff;
	text-transform: uppercase;
	background: url(../img/bg_table_th.png) repeat-x 0 -10px #999999;
}

#carrierTable td {
	padding: 15px;
	font-weight: bold;
	border-right: 1px solid #e9e9e9;
}

#carrierTable td.carrier_price {
	border: none;
}

p.checkbox.gift {
	margin-bottom: 10px;
}

#gift_div {
	display: none;
	margin-top: 5px;
	margin-bottom: 10px;
}

a.iframe {
	color: #333;
	text-decoration: underline;
}

a.iframe:hover {
	text-decoration: none;
}

p.payment_module {
	margin-bottom: 10px;
}

p.payment_module a {
	display: block;
	border: 1px solid #d6d4d4;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	font-size: 17px;
	line-height: 23px;
	color: #333;
	font-weight: bold;
	padding: 33px 40px 34px 99px;
	letter-spacing: -1px;
	position: relative;
}

p.payment_module a.bankwire {
	background: url(../img/bankwire.png) 15px 12px no-repeat #fbfbfb;
}

p.payment_module a.cheque {
	background: url(../img/cheque.png) 15px 15px no-repeat #fbfbfb;
}

p.payment_module a.cash {
	background: url(../img/cash.png) 15px 15px no-repeat #fbfbfb;
}

p.payment_module a.cheque:after,
p.payment_module a.bankwire:after,
p.payment_module a.cash:after {
	display: block;
	content: "\f054";
	position: absolute;
	right: 15px;
	margin-top: -11px;
	top: 50%;
	font-family: "FontAwesome";
	font-size: 25px;
	height: 22px;
	width: 14px;
	color: #777;
}

p.payment_module a:hover {
	background-color: #f6f6f6;
}

p.payment_module a span {
	font-weight: bold;
	color: #777;
}

.payment_module.box {
	margin-top: 20px;
}

p.cheque-indent {
	margin-bottom: 9px;
}

.cheque-box .form-group {
	margin-bottom: 0px;
}

.cheque-box .form-group .form-control {
	width: 269px;
}

.order-confirmation.box {
	line-height: 29px;
}

.order-confirmation.box a {
	text-decoration: underline;
}

.order-confirmation.box a:hover {
	text-decoration: none;
}

.delivery_option_carrier td {
	width: 200px;
}

.delivery_option_carrier td+td {
	width: 280px;
}

.delivery_option_carrier td+td+td {
	width: 200px;
}

.delivery_option_carrier tr td {
	padding: 5px;
}

.delivery_option_carrier.selected {
	display: table;
}

.not-displayable {
	display: none;
}

#address .box,
#identity .box,
#account-creation_form .box,
#new_account_form .box,
#opc_account_form .box,
#authentication .box {
	padding-bottom: 20px;
	line-height: 20px;
}

#address p.required,
#identity p.required,
#account-creation_form p.required,
#new_account_form p.required,
#opc_account_form p.required,
#authentication p.required {
	color: #f13340;
	margin: 9px 0 16px 0;
}

#address p.inline-infos,
#identity p.inline-infos,
#account-creation_form p.inline-infos,
#new_account_form p.inline-infos,
#opc_account_form p.inline-infos,
#authentication p.inline-infos {
	color: red;
}

#address .form-group,
#identity .form-group,
#account-creation_form .form-group,
#new_account_form .form-group,
#opc_account_form .form-group,
#authentication .form-group {
	margin-bottom: 4px;
}

#address .form-group .form-control,
#identity .form-group .form-control,
#account-creation_form .form-group .form-control,
#new_account_form .form-group .form-control,
#opc_account_form .form-group .form-control,
#authentication .form-group .form-control {
	max-width: 271px;
}

#address .form-group .form-control#adress_alias,
#identity .form-group .form-control#adress_alias,
#account-creation_form .form-group .form-control#adress_alias,
#new_account_form .form-group .form-control#adress_alias,
#opc_account_form .form-group .form-control#adress_alias,
#authentication .form-group .form-control#adress_alias {
	margin-bottom: 20px;
}

@media (min-width: 1200px) {

	#address .form-group.phone-number,
	#identity .form-group.phone-number,
	#account-creation_form .form-group.phone-number,
	#new_account_form .form-group.phone-number,
	#opc_account_form .form-group.phone-number,
	#authentication .form-group.phone-number {
		float: left;
		width: 270px;
		margin-right: 13px;
	}
}

@media (min-width: 1200px) {

	#address .form-group.phone-number+p,
	#identity .form-group.phone-number+p,
	#account-creation_form .form-group.phone-number+p,
	#new_account_form .form-group.phone-number+p,
	#opc_account_form .form-group.phone-number+p,
	#authentication .form-group.phone-number+p {
		padding: 23px 0 0 0px;
		margin-bottom: 0;
	}
}

#address .gender-line,
#identity .gender-line,
#account-creation_form .gender-line,
#new_account_form .gender-line,
#opc_account_form .gender-line,
#authentication .gender-line {
	margin-bottom: 4px;
	padding-top: 4px;
}

#address .gender-line>label,
#identity .gender-line>label,
#account-creation_form .gender-line>label,
#authentication .gender-line>label {
	margin-right: 10px;
	margin-bottom: 0;
}

#address .gender-line .radio-inline label,
#identity .gender-line .radio-inline label,
#account-creation_form .gender-line .radio-inline label,
#new_account_form .gender-line .radio-inline label,
#opc_account_form .gender-line .radio-inline label,
#authentication .gender-line .radio-inline label {
	font-weight: normal;
	color: #777777;
}

#identity #center_column form.std .row,
#authentication #center_column form.std .row,
#order-opc #center_column form.std .row {
	margin-left: -5px;
	margin-right: -5px;
}

#identity #center_column form.std .row .col-xs-4,
#authentication #center_column form.std .row .col-xs-4,
#order-opc #center_column form.std .row .col-xs-4 {
	padding-left: 5px;
	padding-right: 5px;
	max-width: 94px;
}

#identity #center_column form.std .row .col-xs-4 .form-control,
#authentication #center_column form.std .row .col-xs-4 .form-control,
#order-opc #center_column form.std .row .col-xs-4 .form-control {
	max-width: 84px;
}

#new_account_form .box {
	line-height: 20px;
}

#new_account_form .box .date-select {
	padding-bottom: 10px;
}

#new_account_form .box .top-indent {
	padding-top: 10px;
}

#new_account_form .box .customerprivacy {
	padding-top: 15px;
}

#authentication fieldset.account_creation {
	margin-bottom: 20px;
}

#password {
	padding-top: 0;
	border: none;
}

#password .box p {
	margin-bottom: 9px;
}

#password .box .form-group {
	margin-bottom: 20px;
}

#password .box .form-group .form-control {
	width: 263px;
}

#instant_search_results a.close {
	font-size: 13px;
	font-weight: 600;
	opacity: 1;
	line-height: inherit;
	text-transform: none;
	font-family: Arial, Helvetica, sans-serif;
}

#instant_search_results a.close:hover {
	color: #515151;
}

#manufacturers_list h3,
#suppliers_list h3 {
	font-size: 17px;
	line-height: 23px;
}

#manufacturers_list .description,
#suppliers_list .description {
	line-height: 18px;
}

#manufacturers_list .product-counter a,
#suppliers_list .product-counter a {
	font-weight: bold;
	color: #333;
}

#manufacturers_list .product-counter a:hover,
#suppliers_list .product-counter a:hover {
	color: #515151;
}

#manufacturers_list.list li .mansup-container,
#suppliers_list.list li .mansup-container {
	border-top: 1px solid #d6d4d4;
	padding: 31px 0 30px 0;
}

@media (max-width: 767px) {

	#manufacturers_list.list li .mansup-container,
	#suppliers_list.list li .mansup-container {
		text-align: center;
	}
}

#manufacturers_list.list li:first-child .mansup-container,
#suppliers_list.list li:first-child .mansup-container {
	border-top: 0;
}

#manufacturers_list.list li .left-side,
#suppliers_list.list li .left-side {
	text-align: center;
}

#manufacturers_list.list li h3,
#suppliers_list.list li h3 {
	margin-top: -5px;
	padding-bottom: 8px;
}

#manufacturers_list.list li .middle-side,
#suppliers_list.list li .middle-side {
	padding-left: 0;
}

@media (max-width: 767px) {

	#manufacturers_list.list li .middle-side,
	#suppliers_list.list li .middle-side {
		padding-right: 15px;
		padding-left: 15px;
	}
}

#manufacturers_list.list li .right-side-content,
#suppliers_list.list li .right-side-content {
	border-left: 1px solid #d6d4d4;
	padding: 0 0 32px 31px;
	min-height: 108px;
}

@media (max-width: 767px) {

	#manufacturers_list.list li .right-side-content,
	#suppliers_list.list li .right-side-content {
		min-height: 1px;
		padding: 0;
		border: none;
	}
}

#manufacturers_list.list li .right-side-content .product-counter,
#suppliers_list.list li .right-side-content .product-counter {
	position: relative;
	top: -6px;
	margin-bottom: 12px;
}

@media (max-width: 767px) {

	#manufacturers_list.list li .right-side-content .product-counter,
	#suppliers_list.list li .right-side-content .product-counter {
		top: 0;
	}
}

#manufacturers_list.grid li,
#suppliers_list.grid li {
	text-align: center;
}

#manufacturers_list.grid li .product-container,
#suppliers_list.grid li .product-container {
	padding-top: 40px;
	padding-bottom: 20px;
	border-bottom: 1px solid #d6d4d4;
}

#manufacturers_list.grid li .product-container .left-side,
#suppliers_list.grid li .product-container .left-side {
	padding-bottom: 42px;
}

#manufacturers_list.grid li .product-container h3,
#suppliers_list.grid li .product-container h3 {
	padding-bottom: 10px;
}

#manufacturers_list.grid li .product-container .product-counter,
#suppliers_list.grid li .product-container .product-counter {
	margin-bottom: 12px;
}

@media (min-width: 1200px) {

	#manufacturers_list.grid li.first-in-line,
	#suppliers_list.grid li.first-in-line {
		clear: left;
	}

	#manufacturers_list.grid li.last-line .product-container,
	#suppliers_list.grid li.last-line .product-container {
		border-bottom: none;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {

	#manufacturers_list.grid li.first-item-of-tablet-line,
	#suppliers_list.grid li.first-item-of-tablet-line {
		clear: left;
	}

	#manufacturers_list.grid li.last-tablet-line .product-container,
	#suppliers_list.grid li.last-tablet-line .product-container {
		border-bottom: none;
	}
}

@media (max-width: 767px) {

	#manufacturers_list.grid .product-container,
	#suppliers_list.grid .product-container {
		border-bottom: none;
	}
}

#manufacturer .description_box,
#supplier .description_box {
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid #d6d4d4;
}

#manufacturer .description_box .hide_desc,
#supplier .description_box .hide_desc {
	display: none;
}

#manufacturer .bottom-pagination-content,
#manufacturer .top-pagination-content,
#supplier .bottom-pagination-content,
#supplier .top-pagination-content {
	min-height: 69px;
}

#manufacturer .top-pagination-content.bottom-line,
#supplier .top-pagination-content.bottom-line {
	border-bottom: 1px solid #d6d4d4;
}

table.discount i {
	font-size: 20px;
	line-height: 20px;
	vertical-align: -2px;
}

table.discount i.icon-ok {
	color: #46a74e;
}

table.discount i.icon-remove {
	color: #f13340;
}

#guestTracking .form-control {
	max-width: 271px;
}

@media (min-width: 1200px) {
	#guestTracking .form-group {
		overflow: hidden;
	}

	#guestTracking .form-group i {
		padding-left: 10px;
	}

	#guestTracking .form-control {
		float: left;
	}

	#guestTracking label {
		float: left;
		clear: both;
		width: 100%;
	}
}

#pagenotfound .pagenotfound {
	max-width: 824px;
	margin: 100px auto 100px auto;
	text-align: center;
}

#pagenotfound .pagenotfound .img-404 {
	padding: 8px 0 27px 0;
}

#pagenotfound .pagenotfound .img-404 img {
	max-width: 100%;
}

#pagenotfound .pagenotfound h1 {
	font: 600 28px/34px "Open Sans", sans-serif;
	color: #333;
	text-transform: uppercase;
	margin-bottom: 7px;
}

#pagenotfound .pagenotfound p {
	font: 600 16px/20px "Open Sans", sans-serif;
	color: #555454;
	text-transform: uppercase;
	border-bottom: 1px solid #d6d4d4;
	padding-bottom: 19px;
	margin-bottom: 20px;
}

#pagenotfound .pagenotfound h3 {
	font-weight: bold;
	color: #333;
	font-size: 13px;
	line-height: normal;
	margin-bottom: 18px;
}

#pagenotfound .pagenotfound label {
	font-weight: normal;
}

@media (max-width: 767px) {
	#pagenotfound .pagenotfound label {
		display: block;
	}
}

#pagenotfound .pagenotfound .form-control {
	max-width: 293px;
	display: inline-block;
	margin-right: 5px;
}

@media (max-width: 767px) {
	#pagenotfound .pagenotfound .form-control {
		margin: 0 auto 15px auto;
		display: block;
	}
}

#pagenotfound .pagenotfound .buttons {
	padding: 48px 0 20px 0;
}

#pagenotfound .pagenotfound .buttons .button-medium i.left {
	font-size: 17px;
}

#account-creation_form fieldset.reversoform {
	padding: 10px !important;
}

#account-creation_form fieldset.reversoform .text label {
	display: block;
	padding: 0 0 10px 0;
	width: 100%;
	text-align: left;
}

#account-creation_form fieldset.reversoform .infos-sup {
	padding: 0 !important;
	font-size: 10px;
	font-style: italic;
	text-align: right;
}

#account-creation_form fieldset.customerprivacy label,
fieldset.customerprivacy label {
	cursor: pointer;
}

form#sendComment fieldset {
	padding: 10px;
	border: 1px solid #cccccc;
	background: #eeeeee;
}

form#sendComment h3 {
	font-size: 14px;
}

#new_comment_form p.text {
	margin-bottom: 0;
	padding-bottom: 0;
}

#sendComment p.text label,
#sendComment p.textarea label {
	display: block;
	margin: 12px 0 4px;
	font-weight: bold;
	font-size: 12px;
}

#sendComment p.text input {
	padding: 0 5px;
	height: 28px;
	width: 498px;
	border: 1px solid #cccccc;
	background: url(../img/bg_input.png) repeat-x 0 0 #fff;
}

#sendComment p.textarea textarea {
	height: 80px;
	width: 508px;
	border: 1px solid #cccccc;
}

#sendComment p.submit {
	padding: 0;
	text-align: right;
}

#sendComment p.closeform {
	float: right;
	padding: 0;
	height: 12px;
	width: 12px;
	text-indent: -5000px;
	background: url(../img/icon/delete.gif) no-repeat 0 0;
}

.star {
	position: relative;
	top: -1px;
	float: left;
	width: 14px;
	overflow: hidden;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
}

.star {
	display: block;
}

.star a {
	display: block;
	position: absolute;
	text-indent: -5000px;
}

div.star:after {
	content: "\f006";
	font-family: "FontAwesome";
	display: inline-block;
	color: #777676;
}

div.star.star_on {
	display: block;
}

div.star.star_on:after {
	content: "\f005";
	font-family: "FontAwesome";
	display: inline-block;
	color: #ef8743;
}

div.star.star_hover:after {
	content: "\f005";
	font-family: "FontAwesome";
	display: inline-block;
	color: #ef8743;
}

.advertising_block {
	width: 191px;
	margin-bottom: 1em;
	text-align: center;
}

.blockcategories_footer ul ul {
	display: none !important;
}

.radio-inline,
.checkbox {
	padding-left: 0;
}

.radio-inline .checker,
.checkbox .checker {
	float: left;
}

.radio-inline .checker span,
.checkbox .checker span {
	top: 0px;
}

.radio-inline div.radio,
.checkbox div.radio {
	display: inline-block;
}

.radio-inline div.radio span,
.checkbox div.radio span {
	float: left;
	top: 0px;
}

.fancybox-skin {
	background: #fff !important;
}

.fancybox-skin .fancybox-close {
	width: 28px;
	height: 28px;
	background: none;
	font-size: 28px;
	line-height: 28px;
	color: #333;
	text-align: center;
	background: white;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
}

.fancybox-skin .fancybox-close:hover {
	color: #515151;
}

.fancybox-skin .fancybox-close:after {
	content: "\f057";
	font-family: "FontAwesome";
}

#facebook_block,
#cmsinfo_block {
	overflow: hidden;
	background: #f2f2f2;
	min-height: 344px;
	height: 344px;
	padding-right: 29px;
	padding-left: 29px;
}

@media (max-width: 991px) {

	#facebook_block,
	#cmsinfo_block {
		min-height: 354px;
		height: 354px;
		padding-left: 13px;
		padding-right: 13px;
	}
}

@media (max-width: 767px) {

	#facebook_block,
	#cmsinfo_block {
		width: 100%;
		min-height: 1px;
	}
}

#facebook_block h4 {
	padding: 35px 0 0 0;
	margin: 0 0 12px 0;
	font: 300 21px/25px "Open Sans", sans-serif;
	color: #6f6d6d;
}

@media (max-width: 991px) {
	#facebook_block h4 {
		font-size: 18px;
	}
}

@media (max-width: 767px) {
	#facebook_block h4 {
		padding-top: 20px !important;
	}
}

@media (max-width: 768px) {
	#facebook_block h4 {
		font-size: 16px;
	}
}

#facebook_block .facebook-fanbox {
	background: #fff;
	border: 1px solid #aaaaaa;
	padding-bottom: 10px;
}

@media (max-width: 1199px) {
	.zoomdiv {
		display: none !important;
	}
}

#product.content_only div.pb-left-column p.resetimg {
	margin-bottom: 0px;
	padding-top: 0px;
}

#product.content_only div.pb-left-column #thumbs_list_frame {
	margin-bottom: 0px;
}

#product_comparison td.compare_extra_information {
	padding: 10px;
}

#product_comparison #social-share-compare {
	margin-bottom: 50px;
}

.btn-facebook:hover,
.btn-twitter:hover,
.btn-google-plus:hover,
.btn-pinterest:hover {
	border-color: white;
}

.btn-facebook i {
	color: #435f9f;
}

.btn-facebook:hover {
	color: white;
	background-color: #435f9f;
}

.btn-facebook:hover i {
	color: white;
}

.btn-twitter i {
	color: #00aaf0;
}

.btn-twitter:hover {
	color: white;
	background-color: #00aaf0;
}

.btn-twitter:hover i {
	color: white;
}

.btn-google-plus i {
	color: #e04b34;
}

.btn-google-plus:hover {
	color: white;
	background-color: #e04b34;
}

.btn-google-plus:hover i {
	color: white;
}

.btn-pinterest i {
	color: #ce1f21;
}

.btn-pinterest:hover {
	color: white;
	background-color: #ce1f21;
}

.btn-pinterest:hover i {
	color: white;
}

.payment_option_form {
	display: none;
}

.payment_option_logo {
	height: 50px;
	max-height: 50px;
}

.payment_module_adv {
	height: 120px;
	max-height: 120px;
}

.payment_option_selected {
	display: none;
	margin-top: 15px;
}

.payment_option_selected i {
	color: green;
}

.payment_option_cta {
	margin-left: 10px;
	color: #333 !important;
}

.tnc_box {
	background: #fbfbfb;
	border: 1px solid #d6d4d4;
	padding: 14px 18px 13px;
	margin: 0 0 30px 0;
	line-height: 23px;
}

.pointer-box {
	cursor: pointer;
}


.modal-boutiques {
	display: flex;
}

.modal-boutiques .modal-header {
	text-align: center;
}

.modal-boutiques .modal-boutiques-localisation {
	text-align: center;
	padding: 10px;
	border-bottom: 1px solid #e5e5e5;
}

.modal-boutiques .modal-title {
	display: inline-block;
	font-size: 36px;
}

.modal-boutiques .modal-body {
	padding: 0px;
}

.modal-boutiques .modal-content {
	border-radius: 0px;
	border-top: 1px solid #f27b1f;
	border-bottom: 3px solid #f27b1f;
}

.modal-boutiques .modal-footer {
	margin-top: 0px;
	padding: 10px;
	text-align: center;
}

.modal-boutiques .close span {
	font-size: 0px;
}

.modal-boutiques .close span:before {
	content: "\e902";
	color: #ab9d9a;
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'toc' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
	font-size: 16px;
}

.modal-boutiques .close span:hover:before {
	color: #9f8f8c;
}

.modal-boutiques .modal-boutiques-list,
.modal-boutiques .modal-boutiques-google-map {
	width: 100%
}

.modal-boutiques #map {
	height: 100%
}

.modal-boutiques .modal-boutiques-list {
	padding: 20px;
}

.modal-boutiques .modal-boutiques-list a:focus {
	text-decoration: none;
}

.modal-boutiques .modal-boutiques-list .panel {
	margin-top: 1px;
	border: none;
}

.modal-boutiques .modal-boutiques-list .panel.is-open {
	-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	margin-bottom: 15px;
}

.modal-boutiques .modal-boutiques-list .panel .panel-heading {
	border: none;
	padding: 0px;
}

.modal-boutiques .modal-boutiques-list .panel .panel-heading a {
	width: 100%;
	display: inline-block;
	padding: 10px 15px;
	background: #f27b1f;
	color: #ffffff;
}

.modal-boutiques .modal-boutiques-list .panel .panel-heading a.collapsed {
	background: #ab9d9a;
}

.modal-boutiques .modal-boutiques-list .panel .panel-available {
	padding: 10px 15px;
	background: #ab9d9a;
	text-align: center;
	color: #ffffff;
}

.modal-boutiques .modal-boutiques-list .panel .panel-available.panel-available-success {
	background: #46a74e;
}

.modal-boutiques .modal-boutiques-list .panel .panel-available.panel-available-warning {
	background: #f0ad4e;
}

.modal-boutiques .modal-boutiques-list .panel .panel-available.panel-available-danger {
	background: #d9534f;
}

.modal-boutiques .modal-boutiques-list .panel .panel-available.panel-available-loading {
	height: 38px !important;
}

.modal-boutiques .modal-boutiques-list .panel .panel-available.panel-available-loading img {
	position: relative !important;
	top: -5px !important;
	width: 28px !important;
}

.modal-boutiques .modal-boutiques-list .panel .panel-body {
	text-align: center;
}

.modal-boutiques .modal-boutiques-list .panel .panel-body p {
	margin-bottom: 0px;
}

.modal-boutiques .modal-boutiques-list .panel .panel-footer {
	background: white;
	border-top: 1px solid #e5e5e5;
	border-bottom: 3px solid #f27b1f;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.modal-boutiques .modal-boutiques-list .panel .panel-footer .button.button-medium {
	margin-right: 10px;
	border: 1px solid #f27b1f;
}

.modal-boutiques .modal-boutiques-list .panel .panel-footer .button.button-medium:last-child {
	margin-right: 0px;
}

.modal-boutiques .modal-boutiques-list .panel .panel-footer .button.button-medium span {
	font-size: 16px;
}

@media screen and (min-width: 768px) {
	.modal-boutiques .modal-dialog {
		width: 960px;
	}
}

section.row>.elementor {
	width: 100%;
}

@media (max-width: 576px) {
	.AV_fancybox-content {
		width: 95% !important;
		height: 90% !important;
		max-width: none !important;
		max-height: none !important;
	}
}

/*# sourceMappingURL=global.css.map */